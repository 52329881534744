/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "The Arc Sport: A Revolutionary Electric Wake Boat"), "\n", React.createElement(_components.h2, null, "Introduction"), "\n", React.createElement(_components.p, null, "The Arc Sport is a revolutionary electric wake boat with a power output of 570 horsepower. It is designed to cater to eco-conscious yet thrill-seeking water sports enthusiasts. This blog post explores the features, specifications, and pricing of the Arc Sport."), "\n", React.createElement(_components.h2, null, "Key Features"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Power Output:"), " 570 horsepower"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Battery Pack:"), " 226-kilowatt-hour providing over 24 hours of low-speed travel or 4-6 hours of active use"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Charging:"), " Overnight charging with a Level 2 home charger or up to 80% in 45 minutes with a Level 3 DC fast charger"), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Design:"), " Retractable hardtop tower and two displays in the cabin"), "\n"), "\n", React.createElement(_components.h2, null, "Pricing and Reservations"), "\n", React.createElement(_components.p, null, "The Arc Sport is priced at $258,000, and reservations can be made with a $500 refundable deposit."), "\n", React.createElement(_components.p, null, "For more information, you can visit ", React.createElement(_components.a, {
    href: "https://arcboats.com/arc-sport/"
  }, "Arc Boats"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
